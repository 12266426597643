import { graphql, useStaticQuery } from "gatsby"
import apiData from "../data/apiData.json"

const useApiData = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return { siteMetadata: data.site.siteMetadata, apiData }
}

export default useApiData
