import { PricingPage } from "@superops/superops-pricing"
import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import "rc-slider/assets/index.css"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../../components/common/accordian"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import Navigation from "../../components/navigation"
import useApiData from "../../components/useApiData"
import CTAThankYou from "../../components/utility/cta_thank_you"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pricing-new.scss"

function PricingPackage() {
  const { apiData } = useApiData()

  const [currency, setCurrency] = useState("USD")

  useEffect(() => {
    const intercomShow = () => {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("show")
      }
    }

    document.getElementById("chatId2").onclick = function() {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[0].onclick = function(e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[1].onclick = function(e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[2].onclick = function(e) {
      intercomShow()
    }
  })

  const setCurrEPSymbol = currency => {
    setCurrency(currency)
  }

  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function(response) {
      if (
        response.data.continent_name === "Europe" &&
        response.data.country_name !== "United Kingdom"
      ) {
        setCurrEPSymbol("EUR", 65, "€")
      } else if (response.data.country_name === "United Kingdom") {
        setCurrEPSymbol("GBP", 55, "£")
      } else if (
        response.data.country_code === "AU" ||
        response.data.country_name === "Australia"
      ) {
        setCurrEPSymbol("AUD", 95, "$")
      }
    })
  })

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentPricingNewWWW {
            SuperOps {
              pages(where: { title: "Pricing" }) {
                title
                featureGroup
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }
              newPricingLists(orderBy: featureOrder_ASC) {
                featureGroup
                featureName
                newFeature
                plan1
                plan2
                plan3
                plan4
                tooltip
                texts {
                  html
                }
              }
              faqs(where: { page: "Pricing" }) {
                category
                question
                answerrt {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <div className="pricing-wrap">
            {data.SuperOps.pages.map(page => {
              const {
                pageBlock,
                navigationBlock,
                seo,
                ctaBoxes,
                featureGroup,
              } = page
              return (
                <>
                  <Frame seo={seo}>
                    <header>
                      <Navigation links={navigationBlock} page="About us" />
                    </header>
                    <PricingPage
                      isWebsite
                      currentCurrency={currency}
                      purchasePageMeta={apiData.purchasePageMeta}
                      endpointsPrice={apiData.addons}
                      availablePlans={apiData.plans}
                    />
                    <div className="main">
                      <div className="faq">
                        <Container className="Layout-container">
                          <Row className="justify-content-between">
                            <Col lg={4}>
                              {parse(pageBlock[9].content.html)}
                              {parse(pageBlock[10].content.html)}
                            </Col>
                            <Col lg={8}>
                              <div className="section">
                                <CustomAccordion items={data.SuperOps.faqs} />
                                <Row>
                                  <Col lg={5}>
                                    <h5 className="pricing-faq-title fw-bold">
                                      What if I have more questions?
                                    </h5>
                                  </Col>
                                  <Col lg={7}>
                                    <span className="p16">
                                      <p className="pricing-faq-desc">
                                        We're available round the clock to
                                        answer your questions and help you find
                                        the right plan.{" "}
                                        <span id="chatId2">Ping us</span> or
                                        drop us a line at sales@superops.com.
                                      </p>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>

                    <div className="pricing-cta ">
                      <Slide delay="200">
                        <CTA data={ctaBoxes} />
                      </Slide>
                    </div>

                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default PricingPackage
